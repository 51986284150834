.header {
	/*background: $primary-color;*/
	/*box-shadow: 0 2px 5px #eee;*/
	.navbar-light .navbar-nav .nav-link{
		font-weight: 400;
		color: #000;
	}
	.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
		color: $primary-color;
	}

	.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
		color: $primary-color;
	}

	.banner-top-right{
		height: 48px;

		p {
			top: 15px;
	    left: 0;
	    text-align: center;
	    width: 100%;
		}
	}

	.nav-menu {
		// border: none;
	}

  .logo {
    img {
      height: 70px;
      width: auto;
    }
  }

  @media screen and (max-width: 768px) {
    .logo {
      padding: 0;

      img {
        height: 40px;
        width: auto;
      }
    }

    .navbar {
      .btn-link {
        font-size: 1.25rem;
        color: rgba(0,0,0,.5);
        padding: 0;
      }

      .small {
        font-size: 0.8rem;
      }
    }
  }

}


.slider-nav {
	&:hover {
		.nav-cate {
			display: block;
		}
	}
	.cate-title {
		background-color: $primary-color;
    color: #fff;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    margin: 0;
    font-size: 16px;
    position: relative;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .nav-cate {
  	clear: both;
    border: 1px solid #e6e6e6;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    position: absolute;
    display: none;
    width: 90%;
    background: #fff;
    z-index: 2;

    .menu-cate {
      width: 100%;
      background: #fff;
      overflow: visible;
      border-top: none;

      .menu-item {
      	display: block;
  	    overflow: visible;
  	    /*padding: 0 10px;*/
  	    cursor: pointer;
  	    position: relative;
        border-bottom: 1px dashed #ddd;

        &:last-child {
          border-bottom: none;
        }


        &:hover{
          .menu-cate.collapse {
            display: flex;
          }
        }

  	    h3 {
  	    	font-size: 16px;
  	    	color: $text-normal-color;


  	    	a {
  	    		&:hover{
  	    			.menu-cate.collapse {
  	    				display: block;
  	    			}
  	    		}
  	    	}
  	    }

  	    .menu-cate {
	    		position: absolute;
			    top: 0;
			    left: 100%;
          display: none;

          .menu-item {
            white-space: nowrap;
            background: white;
            padding: 4px 0;
            border-bottom: none;

            a {
              font-size: 16px;
              font-weight: 500;
            }


            .fa {
              display: none;
            }

            .menu-cate {
              position: static;
              display: block;
              float: left;

              .menu-item  {
                a {
                  font-size: 1rem;
                  font-weight: normal;
                  padding: 0;
                }
              }
            }
          }

  	    }
      }
    }
  }
}
