.bg_star_rate {

    .star_rate {
        font-size: 16px;
        width: 95px;

        .star_rate_1 {
            overflow: hidden;
        }
        .star_rate_2 {
            width: 95px;
        }
        .star_rate_3 {
            top: 0;
            left: 0;
        }

        .fa {
            color: #ffbe00;
        }
    }
}