.frm-search-header {
	button {
    top: 0;
    right: 8px;
    z-index: 3;
    .fa {
      color: rgba(0,0,0,.5);
    }
  }

  #category-id{
  	border-radius: 4px 0 0 4px;
  }

  .input-group {
  	input {
  		padding-right: 45px;
  	}
  }
}