.owl-carousel.owl-slideshow {
	.owl-nav {
    top: calc(50% - 25px);
    left: 0;
    width: 100%;
    position: absolute;

		.owl-next {
			background: transparent;
	    position: absolute;

			@media screen and (min-width: 768px) {
		    right: 0;
		  }
		  @media screen and (max-width: 768px) {
		    right: 0;
		  }
	    background: 0 0;
	    margin: 0;
	    border-radius: 0;
	    padding: 0 9px;

	    &:hover {
	    	background: transparent;
	    }
		}
    .owl-prev {
    	background: transparent;
	    position: absolute;

			@media screen and (min-width: 768px) {
		    left: 0;
		  }

			@media screen and (max-width: 768px) {
		    left: 0;
			}
	    right: auto;
	    background: 0 0;
	    margin: 0;
	    border-radius: 0;
	    padding: 0 9px;

	    &:hover {
	    	background: transparent;
	    }
		}

		.icon-arrow-left {
	    width: 54px;
	    height: 17px;
	    display: inline-block;
	    background-position: -5px 51px;
	    transform: rotate(90deg);
		}
		.icon-arrow-right {
      width: 54px;
	    height: 17px;
	    display: inline-block;
	    background-position: -5px 51px;
	    transform: rotate(-90deg);
		}
	}

	.item {
		.inner {
			@media screen and (min-width: 1200px){
				bottom: 20%;
			}
			@media screen and (max-width: 1200px){
				bottom: 10%;
			}
			left: 0;

			h4 {
		    font-size: 2rem;
			}

			p {
				margin-bottom: 8px;
			}
		}
	}

}

.owl-slideshow {
	&.owl-theme .owl-nav.disabled+.owl-dots {
		margin-top: -30px;
		margin-bottom: 1.5rem;
		position: relative;
		z-index: 1;
	}

	&.owl-theme .owl-dots .owl-dot.active span, &.owl-theme .owl-dots .owl-dot:hover span {
		border: 1px solid $primary-color;
	}

	&.owl-theme .owl-dots .owl-dot span {
		border: 1px solid $primary-color;
		background: white;
		width: 13px;
    height: 13px;
	}
}