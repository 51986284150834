#hotline_header {
	#hotline_phone {
		font-size: 22px;
    margin-top: -2px;
    line-height: 24px;
    font-weight: 600;
    color: $primary_color;
	}

	#hotline_txt {
		font-size: 12px;
	}
}