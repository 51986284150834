.frm-news-letter {
	.btn-subcriber {
    top: 0;
    right: 0;
    z-index: 3;
  }

  .input-group {
  	input {
  		padding-right: 45px;
  	}
  }
}