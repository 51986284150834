@import 'define';
@import 'global';
@import 'header';
@import 'hotline';
@import 'search_header';
@import 'nav_menu';
@import 'slidershow';
@import 'footer';
@import 'backtop';
@import 'icon';
@import 'owl_banner';
@import 'call';
@import 'custom_fa';
@import 'rate';
@import 'order';
@import 'overwrite_bootstrap';
@import 'effect_menu_border_bottom';

.home {
    h3 {
    	font-size: 1.3rem;

      @media screen and (max-width: 768px) {
        font-size: 1.3rem;
      }
    }


    #home_title {
			h1 {
				font-size: 40px;
				font-weight: 300;

				@media screen and (max-width: 768px) {
					font-size: 30px;

				}
			}
    }

    .embed-responsive {
    	h3 {
				font-size: 18px;
    	}

    	p {
    		font-size: 14px;
    	}
    }

	.bg-cover {
    background-color: #333;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .fa {
    	z-index: 1;
    	bottom: 0.5rem;;
    	left: 0.5rem;
    }
	}

	.opacity-0 {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    transform: scale(0);
	}

	.bg-dark-55 {
    background-color: rgba(0, 0, 0, 0.55) !important;
    z-index: 11;
    top: 0;
    left: 0;
	}

	a:hover .opacity-0 {
    opacity: 1;
    transform: scale(1);
    visibility: visible;
	}


	h3 {
	}

	.box-lydo {
		background: linear-gradient($primary-color, darken($primary-color, 5%));

		.txt-number {
	    border: 1px solid #fff;
	    border-radius: 15px;
	    width: 30px;
	    height: 30px;
	    background: #b5e4ff;
	    color: #1188d6;
	    top: 0;
	    right: 10px;
		}

		.top-number{
			max-width: 50px;
		}

		.h-line2 {
			width: 2px;
	    background: #b5e4ff;
		}
	}

	.box-feedback {
		background: linear-gradient($primary-color, darken($primary-color, 5%));

		#owl-feedback {
			.owl-item {
				.item {
					min-height: 322px;

					.thumb {
						.author {
							position: absolute;
							left: 35%;
							top: -64px;
						}
					}
					.name-author{
						font-size: 16px;
					}
				}
			}
		}
	}

	#owl-adv-home {
		&.owl-theme .owl-nav.disabled+.owl-dots {
			margin-bottom: 1.5rem;
			position: relative;
			z-index: 1;
		}

		&.owl-theme .owl-dots .owl-dot.active span, &.owl-theme .owl-dots .owl-dot:hover span {
			border: 1px solid $primary-color;
			background-color: $primary-color;
		}

		&.owl-theme .owl-dots .owl-dot span {
			border: 1px solid $primary-color;
			background: white;
			width: 25px;
	    height: 7px;
		}

		.border-inner {
			z-index: 1;
	    top: 1rem;
	    left: 1rem;
	    bottom: 1rem;
	    right: 1rem;
		}
	}
	#owl-feedback {
		&.owl-theme .owl-nav.disabled+.owl-dots {
			margin-bottom: 1.5rem;
			position: relative;
			z-index: 1;
		}

		&.owl-theme .owl-dots .owl-dot.active span, &.owl-theme .owl-dots .owl-dot:hover span {
			border: 1px solid white;
			background-color: $primary-color;
		}

		&.owl-theme .owl-dots .owl-dot span {
			border: 1px solid $primary-color;
			background: white;
			width: 13px;
	    height: 13px;
		}
	}

	#owl-product-category-home {
		&.owl-theme .owl-nav [class*=owl-]:hover {
			background: transparent;
			color: $primary-color;
		}

	}

	.tab_cate {
		a {
			padding: 3px;
			text-decoration: none;
	    display: inline-block;
	    color: #757575;
		}

	}

}
