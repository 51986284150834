#owl-partner {
	.owl-item {
		.item {
			margin: 10px;
	    box-shadow: 0 2px 2px #ccc;
	  }
	}
}

.owl-carousel {
	.owl-nav {

		.owl-prev {
			position: absolute;
			top: 38%;
			left: 0;
			color: #7a7a7a;
			background: rgba(255,255,255,0.7);
		}
		.owl-next {
			position: absolute;
			top: 38%;
			right: 0;
			color: #7a7a7a;
			background: rgba(255,255,255,0.7);
		}
	}
}