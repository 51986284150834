.navbar-nav {
	.nav-item {
		float: left;

		.nav-link {
			@media screen and (min-width: 1200px) {
				/*padding-right: 25px;
				padding-left: 25px;*/

				margin-right: 0.5rem;
				margin-left: 0.5rem;
			}

			@media screen and (max-width: 1200px) {
				padding-right: 15px;
				padding-left: 15px;
        font-size: 1rem;
			}
		}
    @media screen and (max-width: 991px) {
      border-bottom: 1px solid #ccc;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }
	}
}

.nav-menu {
  border-bottom: 1px solid #eee;
  transition: top 0.2s ease-in-out;
  /*box-shadow: 0 2px 5px #eee;*/

  @media (min-width: 576px) and (max-width: 991px) {
  	.container {
  		max-width: 100%;
  	}
  }

	&.fixed-top {
		background: white;
    border-bottom: 1px solid #999;
    transition: top 0.2s ease-in-out;
    z-index: 2;

		/*.logo {
			img {
				height: 40px;
		    width: auto;
		  }
		}*/

		&.nav-up {
			top: -120px;
		}

		.navbar {
			padding-top: 0;
			padding-bottom: 0;
		}

		.search-header {
			display: none!important;
		}
		.banner-top-right {
			display: none!important;
		}
	}

	@media screen and (max-width: 991px){
		.navbar-slide {
			position: absolute;
	    top: 0;
	    left: -15px!important;
	    background-color: white;
	    width: 0;
	    z-index: 100000;
	    height: 1000vh;

	    .nav-close {
	    	position: fixed;
		    top: 0.8rem;
		    left: calc(100%  - 55px);
	    }
		}

		.dropdown-toggle::after {
			float: right;
			margin-top: 10px;
		}
	}
}

.dropdown-menu {
  margin: 0!important;

	@media screen and (max-width: 991px) {
		border: none;
		padding-left: 15px;
	}

	.arrow_top {
		width: 10px;
    height: 10px;
    float: left;
    background: white;
    transform: rotate(45deg);
    position: absolute;
    top: -5px;
    left: 10%;
    border-left: 1px solid rgba(0,0,0,.15);
    border-top: 1px solid rgba(0,0,0,.15);
	}

	&.dropdown-menu-right {
    .arrow_top {
    	right: 6%;
    	left: auto;
    }
  }

	a {
		@media screen and (max-width: 768px) {
			padding: .5rem 1rem;
	    display: block;
		}

		img {
			/*display: inline-block;
			width: 100%;*/

		}

		span {
			margin-top: 5px;
			font-size: 14px;
		}
	}
}
