@import 'newsletter';

.footer{
	/*background-color: #2b2b2d;*/

	a {
		color: $text-normal-color;
	}

	h6 {
		font-size: 20px;
	}
	.dropdown-show {
    position: relative;
    width: 100%;
    display: inline-block;
    text-decoration: none;

    @media screen and (max-width: 768px) {
	    padding: 9px;
	    background-color: #f5f5f5;
    }

		&:not(.collapsed) {
			.fa-minus {
				display: block;
			}
			.fa-plus {
				display: none;
			}
		}

		.fa.fa-small {
			float: right;
	    position: absolute;
	    right: 15px;
	    top: 13px;
	    font-size: 18px;

			@media screen and (min-width: 768px) {
	    	display: none!important;
	    }
		}
	}

	@media screen and (min-width: 768px) {
		.collapse {
			display:block!important;
		}
	}
	.collapsed {
		.fa-minus {
			display: none;
		}
	}

	.benefit_6 {
    border: 1px solid #e3eade;
    padding: 30px 30px 30px 40px;
    background-color: #f1f7ec;
    border-radius: 5px;

		.fa {
	    color: rgb(115, 176, 67);
		}
	}
}
.copyright {
	/*background-color: #222222;*/
}