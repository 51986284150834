.border-bottom-effect {
  &:after {
    position: absolute;
    content: '';
    display: block;
    bottom: 0;
    height: 1px;
    left: 0;
    width: 100%;
    background: $primary-color!important;
    -webkit-transform-origin: right center;
    -ms-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    transform: scale(0, 1);
    transition: transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85),
    -webkit-transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  }

  &:hover{
    &:after{
      position: absolute;
      content: '';
      display: block;
      bottom: 0;
      height: 1px;
      left: 0;
      width: 100%;
      background: $primary-color!important;
      -webkit-transform-origin: right center;
      -ms-transform-origin: right center;
      transform-origin: left center;
      -webkit-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      transform: scale(1, 1);
      transition: transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85),
      -webkit-transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85);
    }
  }

  &.active {
    /*border-bottom: 1px solid $primary-color;*/
    color: $primary-color!important;

    &:after{
      -webkit-transform-origin: left center;
      -ms-transform-origin: left center;
      transform-origin: left center;
      -webkit-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  }

}