a.backtotop {
    opacity: 0;
    position: fixed;
    bottom: 87px;
    right: 10px;
    height: 30px;
    width: 30px;
    background-color: $primary-color;
    color: rgb(255, 255, 255);
    text-align: center;
    line-height: 20px;
    padding-top: 1px;
    z-index: 9999;
    border-width: 2px;
    border-style: solid;
    border-color: $primary-color;
    border-image: initial;
    border-radius: 30px;
    box-shadow: 0 0px 15px;

    &:hover {
        color: #fff;
        background-color: darken($primary-color, 10%);
    }

    &.is-visible {
      opacity: 1;
    }

    &.fade-out {
      opacity: 1;
    }

    .fa {
      padding-right: 5px;
      padding-left: 5px;
    }
}