.shoping-cart {
	.cart {
		.number_cart {
			border-radius: 50%;
	    background: $primary-color;
	  /*  -webkit-filter: invert(1);
	    -moz-filter: invert(1);
	    -ms-filter: invert(1);
	    -o-filter: invert(1);
	    filter: invert(1);*/
	    min-width: 20px;
	    height: 20px;
	    line-height: 20px;
	    float: right;
	    color: white;
	    text-align: center;
	    top: 0;
	    right: 2px;

	    @media screen and (max-width: 991px) {
		    left: 18px;
	    }
		}
	}
}

.input-qty {
	width: 75px!important;
}