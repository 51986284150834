.icon {
  background-image: url(../img/icon.png);
}

.icon-facebook {
  width: 35px;
  height: 35px;
  display: inline-block;
  background-position: -37px 0;
}

.icon-google {
  width: 35px;
  height: 35px;
  display: inline-block;
  background-position: -37px -44px;
}

.icon-skype {
  width: 35px;
  height: 35px;
  display: inline-block;
  background-position: -37px -85px;
}
.icon-twitter {
  width: 35px;
  height: 35px;
  display: inline-block;
  background-position: -99px 0;
}

.icon-instagram {
  width: 35px;
  height: 35px;
  display: inline-block;
  background-position: -99px -85px;
}

.icon-youtube {
  width: 35px;
  height: 35px;
  display: inline-block;
  background-position: -99px -44px;
}
.icon-pinterest {
  width: 35px;
  height: 35px;
  display: inline-block;
  background-position: -99px -128px;
}

.icon-address {
  width: 12px;
  height: 20px;
  display: inline-block;
  background-position: 0 0;
  filter: invert(1) hue-rotate(114deg);
}

.icon-phone {
  filter: invert(1) hue-rotate(114deg);
  width: 12px;
  height: 20px;
  display: inline-block;
  background-position: 0 -42px;
}

.icon-email {
  filter: invert(1) hue-rotate(114deg);
  width: 12px;
  height: 20px;
  display: inline-block;
  background-position: 0 -81px;
}

.icon-arrow-down {
  width: 54px;
  height: 17px;
  display: inline-block;
  background-position: 0 17px;
}


.icon-shopping-cart {
  width: 20px;
  height: 18px;
  margin-top: -3px;
  display: inline-block;
  background-position: -57px 18px;
}



/**/

.icon-facebook2 {
  width: 27px;
  height: 27px;
  display: inline-block;
  background-position: -157px 0;
}

.icon-twitter2 {
  width: 27px;
  height: 27px;
  display: inline-block;
  background-position: -198px 0;
}

.icon-google2 {
  width: 27px;
  height: 27px;
  display: inline-block;
  background-position: -351px 0;
}

.icon-instagram2 {
  width: 27px;
  height: 27px;
  display: inline-block;
  background-position: -318px 0;
}

.icon-youtube2 {
  width: 27px;
  height: 27px;
  display: inline-block;
  background-position: -280px 0;
}
.icon-tripadvisor2 {
  width: 27px;
  height: 27px;
  display: inline-block;
  background-position: -239px 0;
}