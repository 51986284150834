h1 {
	font-size: 1.4rem;
	font-weight: 400;
}
h2 {
	font-size: 1.3rem;
	font-weight: 400;
}

h3 {
	font-size: 1.3rem;
	font-weight: 400;
}
h4 {
	font-size: 1.2rem;
	font-weight: 400;
}
/*@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Regular.eot');
	src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-Regular.woff2') format('woff2'), url('../fonts/Roboto-Regular.woff') format('woff'), url('../fonts/Roboto-Regular.ttf') format('truetype'), url('../fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
}*/
body {
	background: #f0f0f070;
	/*font-size: 13px;*/
	/*font-family: 'Roboto';*/

	@media screen and (max-width: 768px) {
    font-size: 13px;
	}
}

@media (min-width: 1200px) {
	.container {
	    max-width: 1220px;
	}
}

/*no-image*/
.fa-picture-o {
	font-size: 3rem;
    color: #ddd;
}

.title {
	/*font-size: 16px;*/
	// font-weight: 500;
}

/*hiển thị khi hover*/
.item-hover {
	&:hover{
		border: 1px solid $primary-color;
	}
}
/*bỏ màu border*/
.no-border {
  border-color: transparent;
}

.bg-gray {
  background-color: #ebebeb;
}

.bg-gray-light {
  background-color: #f5f5f5;
}

.bg-blue {
	background-color: #127ee4;
}

.bg-red {
	background-color: #e73918!important;
}

.btn-red {
	background-color: #e73918!important;
}

.text-red {
	color: #e73918!important;
}

.text-price {
	color: $second-color!important;
}

.box-price {
	font-size: 17px;
}


/**
 * Zoom image
 */
.img-zoom {
	overflow: hidden;

	img {
		-webkit-transition-duration: .5s;
		-moz-transition-duration: .5s;
		-ms-transition-duration: .5s;
		-o-transition-duration: .5s;
		transition-duration: .5s;
	}

	&:hover{
		img {
	    -webkit-transform: scale(1.15);
	    -moz-transform: scale(1.15);
	    -ms-transform: scale(1.15);
	    -o-transform: scale(1.15);
	    transform: scale(1.15);
	  }
	}
}

.border-radius-circle {
	border-radius: 2rem;
}

.box-shadow {
	box-shadow: 0 2px 3px #ccc;
  padding: 8px;
}


.line-block {
  background-color: $primary-color;
  height: 1px;
  display: inline-block;
  width: 80px;
}

.body-transparent {
	#mask {
		background: rgba(0,0,0,.8);
		float: left;
		position: absolute;
		z-index: 1999;
		top: 0;
		left: 0;
		width: 100%;
	  height: 1000vh;
	}

	.fixed-top {
		position: inherit;
	}
}

.right-0 {
	right: 0;
}

.fb_iframe_widget iframe {
  width: 100%!important;
}

@font-face {
    font-family: 'Lobster-Regular';
    src: url('../fonts/Lobster-Regular.eot');
    src: url('../fonts/Lobster-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lobster-Regular.woff2') format('woff2'),
        url('../fonts/Lobster-Regular.woff') format('woff'),
        url('../fonts/Lobster-Regular.ttf') format('truetype'),
        url('../fonts/Lobster-Regular.svg#Lobster1.3') format('svg');
    font-weight: normal;
    font-style: normal;
}



.title-labambi {
	font-family: Lobster-Regular;
	color: #primary-color;
	font-size: 30px;
	font-weight: 500;

	span {
		position: relative;

		&::before {
	    content: "";
	    background: url(../img/intro-right.png) bottom right no-repeat;
	    content: " ";
	    width: 55px;
	    height: 26px;
	    position: absolute;
	    bottom: -1px;
	    right: -50px;
	  }
	  &::after {
      content: "";
      background: url(../img/intro-left.png)bottom left no-repeat;
      content: " ";
      width: 55px;
      height: 26px;
      position: absolute;
      bottom: -1px;
      left: -50px;
	  }
	}
}